<template>
  <LayoutSuperadmin>
    <template v-slot:title>{{ title }}</template>
    <div class="order-request h-100 bg-gray-light-4">
      <div class="p-2 p-lg-5">
        <AppLoading fillSpace v-if="isLoading"></AppLoading>
        <BuyerOrderTable
          v-else
          :items="orders"
          :status="status"
          :sort="sorts"
          @sort="sortBy"
          @pageChange="pageChange"
          @cloned-order="fetchData"
          @approved-order="fetchData"
          @rejected-order="fetchData"
        ></BuyerOrderTable>
      </div>
    </div>
  </LayoutSuperadmin>
</template>

<script>
import Orders from '@/shared/api/Orders';
import AppLoading from '@/shared/elements/AppLoading.vue';
import BuyerOrderTable from '@/components/Partial/Orders/OrderTables/BuyerOrderTable.vue';
import _appApiHelper from '@/shared/mixins/_appApiHelper';
import LayoutSuperadmin from '../../../../components/Partial/Layouts/LayoutSuperadmin/LayoutSuperadmin.vue';
import { STATUS } from '@/shared/constants/OrderConstants';

export default {
  name: 'SuperOrderList',

  components: {
    AppLoading,
    BuyerOrderTable,
    LayoutSuperadmin,
  },

  mixins: [_appApiHelper],

  data() {
    return {
      orders: {},
      sorts: [{ item: 'order_created_at', orderBy: 'DESC' }],
      status: this.$route.meta.status,
      title: this.$route.meta.title,
      isLoading: false,
    };
  },

  watch: {
    '$route.path': {
      handler() {
        this.status = this.$route.meta.status;
        this.title = this.$route.meta.title;
        this.fetchData();
      },
    },
  },

  methods: {
    async fetchOrders(page = 1) {
      this.isLoading = true;

      const sorts = {};

      this.sorts.forEach((value) => {
        sorts[value.item] = value.orderBy.toLowerCase();
      });

      let orderParams = {
        status: this.status,
        page,
        ...sorts,
      };

      if (this.status === STATUS.REQUEST_APPROVED.value) {
        orderParams.exclude_quoted = 1;
      }

      if (this.status === STATUS.QUOTE_SENT.value) {
        /**
         * The status in the backend is not quote_sent for orders with quote
         * It remains are approved so we need these 2 parameters
         */
        orderParams.status = STATUS.REQUEST_APPROVED.value;
        orderParams.quotation_status = 'submitted';
      }

      if (this.status === STATUS.QUOTE_APPROVED.value) {
        orderParams.quotation_status = 'approved';
      }

      if (this.status === STATUS.REQUEST_DECLINED.value) {
        orderParams.exclude_quoted = true;
      }

      const orders = await Orders.getOrders({
        params: orderParams,
      });

      this.isLoading = false;

      return orders.data;
    },
    async fetchData(page = 1) {
      this.orders = await this.fetchOrders(page);
    },
    async sortBy(sorts) {
      this.sorts = sorts;
      await this.fetchData();
    },
    async pageChange(page) {
      this.fetchData(page);
    },
  },

  async mounted() {
    await this.fetchData();
  },
};
</script>

<style lang="scss" scoped>
.order-request {
  &-action {
    p {
      // display: none;
      max-width: 0;
      overflow: hidden;
      transition: max-width 0.5s ease-in-out;
      white-space: nowrap;
    }
    &:hover {
      p {
        max-width: 200px;
      }
    }
  }
}
</style>
